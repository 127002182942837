<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12 text-center py-4 pt-8>
        <span
          style="font-size: 25px; color: black; font-family: poppinsbold; font-weight: 500"
        >
          Terms And Conditions</span
        >
      </v-flex>
      <v-flex xs12 sm11 md10 lg9 xl8 pb-16 text-justify>
        <span
          style="font-family: poppinsmedium; font-size: 18px; color: #1a1919"
          v-html="pageData.details"
        ></span>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      pageData: {},
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      axios({
        method: "GET",
        url: "/termsAndCondetions",
        // params: {
        //   Name: "Terms and Conditions",
        // },
      })
        .then((response) => {
          this.pageData = response.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>